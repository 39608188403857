* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: var(--font-size);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family-headers);
    font-weight: var(--font-weight-medium);
}

h1 {
    font-size: 2em;
    margin: 0 0 var(--gutter-2);
}

h2 {
    font-size: 1.6em;
    margin: 0 0 var(--gutter-2);
}

h3 {
    font-size: 1.3em;
    margin: 0 0 var(--gutter-2);
}

*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h5) {
    font-family: var(--font-family-text);
}

a {
    color: var(--color-text);
}
a:hover {
    color: var(--color-primary);
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background: var(--color-scrollbar-track);
}

*::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-thumb);
}

*:hover::-webkit-scrollbar-track {
    background: var(--color-border);
}

*:hover::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-thumb-hover);
}