.container {
    position: relative;
    display: inline-flex;
}

.container input {
    display: none;
}

.label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--gutter-1);
}

.container input:checked + .icon {
    border-color: transparent;
    background-color: var(--color-primary);
}

.container .icon svg {
    opacity: 0;
}

.container input:checked + .icon svg {
    opacity: 1;
}

.container input:disabled + .label,
.container input:disabled + .icon {
    opacity: 0.5;
}

.icon {
    flex: 0 0 18px;
    background-color: #fff;
    height: 18px;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid var(--color-border);
    align-self: center;
}

.icon svg {
    display: block;
    width: 16px;
    fill: #fff;
    opacity: 0;
}

.text {
    margin-left: 8px;
    flex: 0 1 auto;
}

.disabled {
    pointer-events: none;
}
