.sub-navigation {
    display: flex;
    flex-flow: column;
    width: var(--width-sub-nav);
    height: 100vh;
    padding: var(--gutter-2) var(--gutter-3);
    background-color: var(--color-background);
    position: relative;
    z-index: 90;
}
.sub-navigation-header {
    margin: 4px 0 var(--gutter-3);
}

.sub-navigation-list {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

.sub-navigation-item {
    margin-bottom: 8px;
    overflow: hidden;
}