.wrapper {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: var(--width-main-nav);
    background-color: var(--color-primary);
}

.content {
    width: calc(100vw - var(--width-main-nav));
    background-color: #fff;
}