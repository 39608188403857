.label {
    display: inline-flex;
    align-items: center;
    padding: var(--gutter-1) 0;
}

.info {
    margin-right: var(--gutter-1);
    cursor: help;
}

.info svg {
    display: block;
    width: var(--gutter-2);
    fill: var(--color-text);
}
