.Toastify__toast {
	color: #fff;
	border-radius: var(--border-radius-2);
	box-shadow: var(--box-shadow-3)
}

.Toastify__close-button svg {
	fill: #fff;
}

.Toastify__toast-icon svg {
	fill: #fff;
}

.Toastify__progress-bar {
	background: rgba(255, 255, 255, 0.5);
}

.Toastify__toast--default {
	background-color: var(--color-primary);
}

.Toastify__toast--info {
	background-color: var(--color-info);
}

.Toastify__toast--success {
	background-color: var(--color-success);
}

.Toastify__toast--warning {
	background-color: var(--color-warning);
}

.Toastify__toast--error {
	background-color: var(--color-danger);
}