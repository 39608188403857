.wrapper {
    flex: 1;
    height: 100vh;
    background-color: #fff;
    padding: var(--gutter-1) var(--gutter-5);
    overflow: auto;
}

.breadcrumbs {
    padding: var(--gutter-2) 0;
    margin-top: calc(var(--gutter-1) * -1);
    background-color: #fff;
    z-index: 1;
}

.content {
    padding-top: 10px;
}