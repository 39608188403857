.container {
    display: flex;
    min-height: 100vh;
}

.navigation {
    min-height: calc(100vh - var(--height-header));
    width: var(--width-primary-nav);
}

.main-navigation {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: var(--width-main-nav);
    height: 100%;
    padding: var(--gutter-2);
    background-color: var(--color-primary);
}

.main-navigation-header {
    width: 48px;
    height: 30px;
    margin: 4px 0 var(--gutter-3);
    overflow: hidden;
    border-radius: var(--border-radius-2);
}

.main-navigation-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.main-navigation-item {
    width: 50px;
    height: 50px;
    margin: 8px 0 var(--gutter-1);
    overflow: hidden;
    border-radius: var(--border-radius-2);
}
