.container input {
    display: none;
}

.label {
    position: relative;
    padding: var(--gutter-1) var(--gutter-1) var(--gutter-1) var(--gutter-3);
    margin-right: var(--gutter-2);
    cursor: pointer;
}

.container input:checked {
    &[disabled] + .icon:before {
        opacity: 0.5;
    }

    + .icon:before {
        background-color: var(--color-primary);
        opacity: 1;
    }
}

.disabled + .label {
    opacity: 0.5;
}

.icon {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translateY(-50%);
    background-color: #fff;
    height: var(--gutter-2);
    width: var(--gutter-2);
    border-radius: 50%;
    border: 1px solid var(--color-border);

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        width: var(--gutter-1);
        height: var(--gutter-1);
        border-radius: 50%;
        opacity: 0;
    }
}

.disabled {
    pointer-events: none;
}