.button {
    position: relative;
    justify-content: center;
    align-items: center;
    color: var(--button-color, #fff);
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    font-weight: var(--font-weight-bold);
    overflow: hidden;
    height: var(--height);
    line-height: var(--height);
    padding: 0 var(--padding-x);
    text-decoration: none;
    transition: background-color var(--state-transition),
                color var(--state-transition),
                border-color var(--state-transition),
                box-shadow var(--state-transition);

    &:hover {
        text-decoration: none;
    }
}

.loader {
    display: flex;
    justify-content: center;
    
    svg {
        position: relative;
        top: 4px;
    }
}

.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    svg {
        display: block;
        width: 16px;
        height: auto;
    }
}

.icon-left {
    margin-right: 4px;
}

.icon-right {
    margin-left: 4px;
}

.icon-left ,
.icon-right {
    width: 16px;
    height: 16px;
    
    svg {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        display: block;
    }
}

/* --- disabled --- */
.disabled {
    opacity: .6;
    pointer-events: none;
}

/* --- displays --- */
.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

.inline-flex {
    display: inline-flex;
}

.flex {
    display: flex;
}

/* --- colors --- */
.primary {
    --button-color: #fff;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.primary:hover {
    --button-color: --color-primary;
    background-color: transparent;
}

.primary:active, .primary:focus {
    box-shadow: 0 0 0 4px var(--color-primary-50);

    &.circle {
        box-shadow: inset 0 0 0 4px var(--color-primary-50);
    }
}

.secondary {
    --button-color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.secondary:hover {
    --button-color: var(--color-secondary);
    background-color: transparent;
}

.secondary:active, .secondary:focus {
    box-shadow: 0 0 0 4px var(--color-secondary-50);

    &.circle {
        box-shadow: inset 0 0 0 4px var(--color-secondary-50);
    }
}

.tertiary {
    --button-color: #fff;
    background-color: var(--color-tertiary);
    border-color: var(--color-tertiary);
}

.tertiary:hover {
    --button-color: var(--color-tertiary);
    background-color: transparent;
}

.tertiary:active, .tertiary:focus {
    box-shadow: 0 0 0 4px var(--color-tertiary-50);

    &.circle {
        box-shadow: inset 0 0 0 4px var(--color-tertiary-50);
    }    
}

.quaternary {
    --button-color: #fff;
    background-color: rgba(255,255,255,.1);
    border-color: transparent;
}

.quaternary:hover {
    background-color: rgba(255,255,255,.15);
}

.quaternary:active, .tertiary:focus {
    background-color: rgba(255,255,255,.2);
    box-shadow: 0 0 0 4px var(--color-tertiary-50);

    &.circle {
        box-shadow: inset 0 0 0 4px var(--color-tertiary-50);
    }    
}

.success {
    --button-color: #fff;
    background-color: var(--color-success);
    border-color: var(--color-success);
}

.success:hover {
    --button-color: var(--color-success);
    background-color: transparent;
}

.success:active, .success:focus {
    box-shadow: 0 0 0 4px var(--color-success-50);
}

.warning {
    --button-color: #fff;
    background-color: var(--color-warning);
    border-color: var(--color-warning);
}

.warning:hover {
    --button-color: --color-warning;
    background-color: transparent;
}

.warning:active, .warning:focus {
    box-shadow: 0 0 0 4px var(--color-warning-50);
}

.danger {
    --button-color: #fff;
    background-color: var(--color-danger);
    border-color: var(--color-danger);
}

.danger:hover {
    --button-color: var(--color-danger);
    background-color: transparent;
}

.danger:active, .danger:focus {
    box-shadow: 0 0 0 4px var(--color-danger-50);
}

.info {
    --button-color: #fff;
    background-color: var(--color-info);
    border-color: var(--color-info);
}

.info:hover {
    --button-color: var(--color-info);
    background-color: transparent;
}

.info:active, .info:focus {
    box-shadow: 0 0 0 4px var(--color-info-50);
}

/* --- variants --- */
.text {
    --button-color: var(--color-text);
    background-color: transparent;
    text-align: center;
    border: none;
}


/* --- shapes --- */
.square {
    border-radius: var(--border-radius-2);
}

.pill {
    border-radius: 50px;
}

.circle {
    width: var(--height);
    padding: 0;
    text-align: center;
    border-radius: 100%;
}

/* --- sizes --- */
.xsmall {
    --height: 36px;
    --padding-x: var(--gutter-2);
    font-size: .8rem;
}

.small {
    --height: 40px;
    --padding-x: var(--gutter-2);
    font-size: .875rem;
}

.medium {
    --height: 46px;
    --padding-x: var(--gutter-3);
    font-size: 1rem;
}

.large {
    --height: 56px;
    --padding-x: var(--gutter-3);
    font-size: 1.2rem;
}

/* --- density --- */
.compact {
    --padding-x: 0;
}

/* --- remaining --- */
.full-width {
    display: flex;
    width: 100%;
}

.auto-height {
    --height: auto;
}

.underline {
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

.button .content-wrapper svg {
    fill: var(--button-color);
}