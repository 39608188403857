.title {
    font-family: var(--font-family-text);
    font-size: 1.5em;
    line-height: 1.3em;
    font-weight: var(--font-weight-bold);
    text-align: center;
    margin: 0;
    color: var(--color-secondary);
}

.subtitle {
    font-family: var(--font-family-headers);
    font-size: 1em;
    text-align: center;
    margin: var(--gutter-1) 0 0;
    color: var(--color-secondary);
}

.field {
    font-family: var(--font-family-headers);
    width: 100%;
    margin: var(--gutter-2) 0 0;
    color: var(--color-secondary);
    text-align: center;
}

.validation {
    text-align: center;
    color: var(--color-danger);
    font-size: .9em;
    margin: var(--gutter-1) 0 0;
}

.actions {
    text-align: center;
    margin: var(--gutter-4) 0 0;
}

.back-button {
    position: relative;
    display: inline-block;
    margin: var(--gutter-2) 0 0;
    cursor: pointer;
    color: var(--color-secondary);
    font-size: 1em;
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
}
.back-button:hover {
    color: var(--color-primary);
}
