.login-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bg:after{
    content: ''; 
    position: absolute; 
    display: block; 
    top: 0; 
    left: 0; 
    height: 100%; 
    width: 100%;
    background: rgba(0, 101, 209, 0.2);
    z-index: 1;
    mix-blend-mode: lighten
}

.bg img {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 101vw;
    min-height: 101vh;
    filter: blur(10px);
}