.container {
    position: relative;
    display: inline-flex;

    input[type="checkbox"] {
        display: none;
    }
}

.remove-icon,
.undo-icon {
    display: inline-block;
    width: 1.1em;
    cursor: pointer;

    svg {
        fill: var(--color-text-muted);
        transition: fill var(--state-transition);
    }
}

.remove-icon:hover svg {
    fill: var(--color-danger);
}

.undo-icon:hover svg {
    fill: var(--color-success);
}

.disabled {
    pointer-events: none;
}