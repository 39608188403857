.tippy-box[data-theme~='tooltip'] {
    padding: calc(var(--gutter-1) / 2);
    border-radius: var(--border-radius-2);
}

.tippy-box[data-theme~='popover'] {
    padding: var(--gutter-1);
    border: solid 1px var(--color-border);
    border-radius: var(--border-radius-2);
    background-color: #fff;
    color: var(--color-text);
    box-shadow: var(--box-shadow-3);
}

.tippy-box[data-theme~='popover'] .tippy-arrow {
    color: var(--color-background);
}