@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

:root {
    --color-primary: #0065D1;
    --color-primary-50: #0065D150;
    --color-primary-20: #0065D120;
    --color-secondary: #003976;
    --color-secondary-50: #00397650;
    --color-secondary-20: #00397620;
    --color-tertiary: #4D4D4D;
    --color-tertiary-50: #4D4D4D50; 
    --color-tertiary-20: #4D4D4D20; 
    
    --color-success: #4eb860;
    --color-success-50: #4eb86050;
    --color-warning: #f89367;
    --color-warning-50: #f8936750;
    --color-danger: #f2455e;
    --color-danger-50: #f2455e50;
    --color-info: #007bff;
    --color-info-50: #007bff50;
    
    --color-background: #F5F5F5;
    --color-border: #dadada;
    --color-text: #2A2A2A;
    --color-text-muted: #b7b7b7;

    --color-scrollbar-track: var(--color-background);
    --color-scrollbar-thumb: #ccc;
    --color-scrollbar-thumb-hover: #888;

    --font-family-headers: "Poppins", sans-serif;
    --font-family-text: "Montserrat", sans-serif;
    --font-family-monospaced: "Courier New", Courier, monospace;
    --font-weight-light: 300;
    --font-weight-medium: 400;
    --font-weight-bold: 500;
    --font-weight-extra-bold: 600;
    
    --gutter-1: 8px;
    --gutter-2: 16px;
    --gutter-3: 24px;
    --gutter-4: 32px;
    --gutter-5: 40px;
    --gutter-6: 48px;
    --gutter-7: 56px;
    --gutter-8: 64px;
    --gutter-9: 72px;
    --gutter-10: 80px;
    --section-gutter: var(--gutter-6);
    --font-size: 14px;
    --border-radius-1: 4px;
    --border-radius-2: 8px;
    --border-radius-3: 12x;
    --border-radius-4: 16px;
    --border-radius-5: 20px;
    --border-radius-6: 24px;
    --border-radius-7: 28px;
    --border-radius-8: 32px;

    --input-height-small: 28px;
    --input-height-medium: 38px;
    --input-height-large: 48px;
    --input-border-radius: 5px;

    --box-shadow-1: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    --box-shadow-2: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.102), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108);
    --box-shadow-3: 0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108);
    --box-shadow-4: 0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18);

    --disabled-opacity: 0.4;
    
    --height-header: 60px;
    --width-main-nav: 80px;
    --width-sub-nav: 270px;
    --size-main-nav-item: 48px;
    --size-main-nav-icon: 1.4em;

    --state-transition: 100ms ease-in-out;
}