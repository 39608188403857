.card {
    position: absolute;
    top: 40%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    background-color: rgba(255, 255, 255, 0.95) !important;
    border: solid 1px var(--color-border);
    z-index: 2;
}