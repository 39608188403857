.title {
    font-family: var(--font-family-text);
    font-size: 2em;
    line-height: 1.3em;
    font-weight: var(--font-weight-bold);
    text-align: center;
    letter-spacing: 1px;
    margin: 0;
    color: var(--color-secondary);
}

.subtitle {
    font-family: var(--font-family-headers);
    font-size: 1em;
    text-align: center;
    margin: var(--gutter-1) 0 0;
    color: var(--color-secondary);
}

.organization {
    display: block;
    margin: var(--gutter-4) 0 var(--gutter-5);
    text-align: center;
}

.current-organization {
    font-family: var(--font-family-headers);
    color: var(--color-secondary);
    margin: 0;
}

.organization-name {
    display: inline-block;
    font-weight: var(--font-weight-extra-bold);
}

.organization-name::first-letter {
    text-transform: uppercase;
}

.change {
    font-family: var(--font-family-headers);
    color: var(--color-secondary);
    text-decoration: underline;
    cursor: pointer;
}
.change:hover {
    color: var(--color-primary);
}