.outer {
    display: flex;
    align-content: center;
    gap: var(--gutter-1);
}

.switch {
    display: inline-block;
    position: relative;
    overflow: hidden;
    padding: var(--gutter-1);
    border: solid 1px var(--color-border);
    background-color: var(--color-text-muted);
    cursor: pointer;
}

.switch.checked {
    background-color: var(--color-primary);
}

.switch input {
    display: none;
}

.disabled {
    opacity: .5;
    cursor: not-allowed;
}

.label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ball {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 50%;
    border: solid 1px var(--color-border);
}

.small .ball {
    width: 11px;
    height: 11px;
    left: 3px;
}

.medium .ball {
    left: 3px;
    width: 12px;
    height: 12px;
}

.large .ball {
    left: 4px;
    width: 16px;
    height: 16px;
}

.small.checked .ball {
    left: calc(100% - 14px);
}

.medium.checked .ball {
    left: calc(100% - 15px);
}

.large.checked .ball {
    left: calc(100% - 20px);
}

.small {
    width: 30px;
    height: 15px;
    border-radius: 15px;
}

.medium {
    width: 40px;
    height: 20px;
    border-radius: 20px;
}

.large {
    width: 50px;
    height: 26px;
    border-radius: 20px;
}