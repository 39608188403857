/* defaults */
.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: flex;
}

.wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
}

.spinner {
    animation: rotate 1s linear infinite;
    z-index: 2;
    width: 45px;
    height: 45px;
    display: inline-block;
}

.circle {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

/* speed */
.speed-fast .spinner {
    animation-duration: .666s;
}

.speed-fast .circle {
    animation-duration: 1s;
}

.speed-normal .spinner {
    animation-duration: 1s;
}

.speed-normal .circle {
    animation-duration: 1.5s;
}

.speed-slow .spinner {
    animation-duration: 1.4s;
}

.speed-slow .circle {
    animation-duration: 2.1s;
}

/* overlay alpha's */
.overlay-alpha-0 {
    background-color: transparent;
}

.overlay-alpha-1 {
    background-color: rgba(255, 255, 255, .1);
}

.overlay-alpha-2 {
    background-color: rgba(255, 255, 255, .2);
}

.overlay-alpha-3 {
    background-color: rgba(255, 255, 255, .3);
}

.overlay-alpha-4 {
    background-color: rgba(255, 255, 255, .4);
}

.overlay-alpha-5 {
    background-color: rgba(255, 255, 255, .5);
}

.overlay-alpha-6 {
    background-color: rgba(255, 255, 255, .6);
}

.overlay-alpha-7 {
    background-color: rgba(255, 255, 255, .7);
}

.overlay-alpha-8 {
    background-color: rgba(255, 255, 255, .8);
}

.overlay-alpha-9 {
    background-color: rgba(255, 255, 255, .9);
}

.overlay-alpha-10 {
    background-color: #fff;
}

/* center */
.centered {
    justify-content: center;
    align-items: center;
}

/* sizes */
.small {
    width: 20px;
    height: 20px;
}

.medium {
    width: 50px;
    height: 50px;
}

.large {
    width: 70px;
    height: 70px;
}

/* colors */
.color-primary {
    stroke: var(--color-primary);
}

.color-secondary {
    stroke: var(--color-secondary);
}

.color-success {
    stroke: var(--color-success);
}

.color-warning {
    stroke: var(--color-warning);
}

.color-danger {
    stroke: var(--color-danger);
}

.color-info {
    stroke: var(--color-info);
}

.color-text {
    stroke: var(--color-text);
}

.color-muted {
    stroke: var(--color-muted);
}

.color-light {
    stroke: #fff;
}
  
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
  
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}