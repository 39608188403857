.card {
    display: block;
    border-radius: var(--border-radius-2);
}

.background-default {
    background-color: var(--color-background);
}

.background-light {
    background-color: #fff;
}

.shadow {
    box-shadow: var(--box-shadow-2);
}

.bordered {
    border: solid 1px var(--color-border);
}

/* gutters */
.gutter-0 { padding: 0; }
.gutter-1 { padding: var(--gutter-1); }
.gutter-2 { padding: var(--gutter-2); }
.gutter-3 { padding: var(--gutter-3); }
.gutter-4 { padding: var(--gutter-4); }
.gutter-5 { padding: var(--gutter-5); }
.gutter-6 { padding: var(--gutter-6); }
.gutter-7 { padding: var(--gutter-7); }

.gutter-top-0 { padding-top: 0; }
.gutter-top-1 { padding-top: var(--gutter-1); }
.gutter-top-2 { padding-top: var(--gutter-2); }
.gutter-top-3 { padding-top: var(--gutter-3); }
.gutter-top-4 { padding-top: var(--gutter-4); }
.gutter-top-5 { padding-top: var(--gutter-5); }
.gutter-top-6 { padding-top: var(--gutter-6); }
.gutter-top-7 { padding-top: var(--gutter-7); }

.gutter-right-0 { padding-right: 0; }
.gutter-right-1 { padding-right: var(--gutter-1); }
.gutter-right-2 { padding-right: var(--gutter-2); }
.gutter-right-3 { padding-right: var(--gutter-3); }
.gutter-right-4 { padding-right: var(--gutter-4); }
.gutter-right-5 { padding-right: var(--gutter-5); }
.gutter-right-6 { padding-right: var(--gutter-6); }
.gutter-right-7 { padding-right: var(--gutter-7); }

.gutter-bottom-0 { padding-bottom: 0; }
.gutter-bottom-1 { padding-bottom: var(--gutter-1); }
.gutter-bottom-2 { padding-bottom: var(--gutter-2); }
.gutter-bottom-3 { padding-bottom: var(--gutter-3); }
.gutter-bottom-4 { padding-bottom: var(--gutter-4); }
.gutter-bottom-5 { padding-bottom: var(--gutter-5); }
.gutter-bottom-6 { padding-bottom: var(--gutter-6); }
.gutter-bottom-7 { padding-bottom: var(--gutter-7); }

.gutter-left-0 { padding-left: 0; }
.gutter-left-1 { padding-left: var(--gutter-1); }
.gutter-left-2 { padding-left: var(--gutter-2); }
.gutter-left-3 { padding-left: var(--gutter-3); }
.gutter-left-4 { padding-left: var(--gutter-4); }
.gutter-left-5 { padding-left: var(--gutter-5); }
.gutter-left-6 { padding-left: var(--gutter-6); }
.gutter-left-7 { padding-left: var(--gutter-7); }