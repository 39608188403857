.input {
    width: auto;
    border: 1px solid var(--color-border);
    border-radius: var(--input-border-radius);
    transition: border-color var(--state-transition);
}

.input:focus {
    outline: none;
    border-color: var(--color-primary);
}

.full {
    width: 100%;
}

.small {
    font-size: .9em;
    height: var(--input-height-small);
    line-height: var(--input-height-small);
    padding: 0 var(--gutter-1);
}

.medium {
    font-size: 1em;
    height: var(--input-height-medium);
    line-height: var(--input-height-medium);
    padding: 0 var(--gutter-1);
}

.large {
    font-size: 1.2em;
    height: var(--input-height-large);
    line-height: var(--input-height-large);
    padding: 0 var(--gutter-2);
}
