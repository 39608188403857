.select {
    width: auto;
    border: 1px solid var(--color-border);
    border-radius: var(--input-border-radius);
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
    cursor: pointer;
    appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjUiIGhlaWdodD0iNS4yODQiIHZpZXdCb3g9IjAgMCA5LjUgNS4yODQiPg0KICA8ZyBpZD0ibmV4dCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOS4yNSAwLjI4KSByb3RhdGUoOTApIj4NCiAgICA8cGF0aCBpZD0iUGF0aF8yNSIgZGF0YS1uYW1lPSJQYXRoIDI1IiBkPSJNNC42ODEsNC4zMjEuNDM1LjA3NWEuMjU1LjI1NSwwLDAsMC0uMzYuMzZMNC4xNCw0LjUuMDc1LDguNTY0YS4yNTQuMjU0LDAsMCwwLDAsLjM2QS4yNTYuMjU2LDAsMCwwLC4yNTQsOWEuMjQ4LjI0OCwwLDAsMCwuMTc5LS4wNzVMNC42NzksNC42NzlBLjI1My4yNTMsMCwwLDAsNC42ODEsNC4zMjFaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDApIiBmaWxsPSIjMmEyYTJhIiBzdHJva2U9IiMyYTJhMmEiIHN0cm9rZS13aWR0aD0iMC41Ii8+DQogIDwvZz4NCjwvc3ZnPg0K);
    background-position: calc(100% - var(--gutter-1)) 55%;
    background-repeat: no-repeat;
    transition: border-color var(--state-transition);

    --padding-right: var(--gutter-4);
}

.select:focus {
    outline: none;
    border-color: var(--color-primary);
}

.full {
    width: 100%;
}

.small {
    font-size: .9em;
    height: var(--input-height-small);
    line-height: var(--input-height-small);
    padding: 0 var(--padding-right) 0 var(--gutter-1);
}

.medium {
    font-size: 1em;
    height: var(--input-height-medium);
    line-height: var(--input-height-medium);
    padding: 0 var(--padding-right) 0 var(--gutter-1);
}

.large {
    font-size: 1.2em;
    height: var(--input-height-large);
    line-height: var(--input-height-large);
    padding: 0 var(--padding-right) 0 var(--gutter-2);
}